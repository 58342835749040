<template>
  <page-wrapper
    :hasFilters="true"
    :isLoading="isLoading"
    @submitFilters="getBonusList"
  >
    <template #title>Bonus List</template>
    <template #titleBtn>
      <b-button type="button" variant="success" @click="renderCreateBonusModal">
        Create New Bonus
      </b-button>
    </template>
    <template #filters>
      <search-input
        @enter="getBonusList"
        v-model="bonusListFilters.title"
        inputId="bonusFilterTitle"
        inputLabel="Bonus Title"
        inputPlaceholder="Enter your bonus title"
      ></search-input>
      <search-input
        @enter="getBonusList"
        v-model="bonusListFilters.fromPrice"
        inputId="bonusFilterFromPrice"
        inputLabel="Bonus Start Price (From)"
        inputPlaceholder="Enter your bonus start price"
      ></search-input>
      <search-input
        @enter="getBonusList"
        v-model="bonusListFilters.toPrice"
        inputId="bonusFilterToPrice"
        inputLabel="Bonus End Price (To)"
        inputPlaceholder="Enter your bonus End price"
      ></search-input>
      <custom-append-text
        @enter="getBonusList"
        v-model="bonusListFilters.price"
        :isText="true"
        inputId="bonusFilterPricePercent"
        inputLabel="The amount of bonus percent (%)"
        inputPlaceholder="Enter your bonus End price"
      >
        <template #append> % </template>
      </custom-append-text>
      <search-input
        @enter="getBonusList"
        v-model="bonusListFilters.cashPerTradeRate"
        inputId="bonusCashPerTradeRate"
        inputLabel="The rate of cash bonus per trade"
        inputPlaceholder="Enter The rate of cash bonus per trade"
      ></search-input>
    </template>
    <custom-table
      @changePage="changePage"
      v-if="bonusList"
      :tableColumns="myTableColumns"
      :tableItems="bonusList"
      :totalCount="totalCount"
      :pageNumber="bonusListFilters.pageNumber"
      :count="bonusListFilters.count"
    >
      <template #actions="data">
        <div class="w-100 d-flex align-items-center justify-content-between">
          <b-button
            type="button"
            variant="transparent"
            size="sm"
            @click="renderUpdateModal(data.item)"
          >
            <feather-icon icon="EditIcon" class="text-primary" size="20" />
          </b-button>
          <b-button
            type="button"
            variant="transparent"
            size="sm"
            @click="renderDeleteModal(data.item)"
          >
            <feather-icon icon="Trash2Icon" class="text-danger" size="20" />
          </b-button>
        </div>
      </template>
    </custom-table>
    <create-new-bonus-modal
      :isLoading="isLoading"
      :okCallback="createANewBonus"
    ></create-new-bonus-modal>
    <delete-bonus-confirmation-modal
      :tempBonus="tempBonus"
      :isLoading="isLoading"
      :okCallback="deleteABonus"
    ></delete-bonus-confirmation-modal>
    <update-bonus-modal
      :tempBonus="tempBonus"
      :isLoading="isLoading"
      :okCallback="updateABonus"
    ></update-bonus-modal>
  </page-wrapper>
</template>
<script>
export default {
  title: "Bonus",
  mounted() {
    this.getBonusList();
  },
  data() {
    return {
      isLoading: false,
      bonusListFilters: {
        pageNumber: 1,
        count: 10,
        id: null,
        title: null,
        fromPrice: null,
        toPrice: null,
        price: null,
        cashPerTradeRate: null,
      },
      totalCount: null,
      bonusList: null,
      myTableColumns: [
        {
          key: "title",
          label: "Bonus Title",
        },
        {
          key: "fromPrice",
          label: "Bonus Start Price (From)",
          formatter: (value) => parseFloat(value),
        },
        {
          key: "toPrice",
          label: "Bonus End Price (To)",
          formatter: (value) => parseFloat(value),
        },
        {
          key: "cashPerTradeRate",
          label: "The rate of cash bonus per trade",
          formatter: (value) => parseFloat(value),
        },
        {
          key: "price",
          label: "The amount of bonus percentage",
          formatter: (value) => `${parseFloat(value)}%`,
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      tempBonus: null,
    };
  },
  methods: {
    deleteUpdateConditions() {
      return true;
    },
    changePage(pageNumber) {
      this.bonusListFilters.pageNumber = pageNumber;
      this.getBonusList();
    },
    async createANewBonus(payload) {
      try {
        this.isLoading = true;
        let _this = this;
        let createANewBonusDeposit = new CreateANewBonusDeposit(_this);
        createANewBonusDeposit.setRequestParamDataObj(payload);
        await createANewBonusDeposit.fetch((res) => {
          if (res.isSuccess) {
            _this.$bvModal.hide("create-new-bonus");
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Created!",
                icon: "CheckIcon",
                variant: "success",
                text: "Bonus Created successfully.",
              },
            });
            _this.getBonusList();
          } else {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Failed",
                icon: "CloseIcon",
                variant: "danger",
                text: res.errorMessage || "Something went wrong.",
              },
            });
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getBonusList() {
      try {
        this.isLoading = true;
        let _this = this;
        let getBonusDepositList = new GetBonusDepositList(_this);
        getBonusDepositList.setRequestParamDataObj(_this.bonusListFilters);
        await getBonusDepositList.fetch((res) => {
          _this.bonusList = res.data;
          _this.totalCount = res.total;
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async deleteABonus() {
      try {
        this.isLoading = true;
        let _this = this;
        let deleteABonusDeposit = new DeleteABonusDeposit(_this);
        deleteABonusDeposit.setRequestParam({ id: _this.tempBonus.id });
        await deleteABonusDeposit.fetch((res) => {
          if (res.isSuccess) {
            _this.$bvModal.hide("delete-a-bonus-confimration-modal");
            _this.tempBonus = null;
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Deleted!",
                icon: "CheckIcon",
                variant: "success",
                text: "Bonus Deleted successfully.",
              },
            });
            _this.getBonusList();
          } else {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Failed",
                icon: "CloseIcon",
                variant: "danger",
                text: res.data.errorMessage || "Something went wrong.",
              },
            });
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async updateABonus(payload) {
      try {
        this.isLoading = true;
        let _this = this;
        let updateABonusDeposit = new UpdateABonusDeposit(_this);
        updateABonusDeposit.setRequestParamDataObj(payload);
        await updateABonusDeposit.fetch((res) => {
          if (res.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Updated!",
                icon: "CheckIcon",
                variant: "success",
                text: "Bonus Updated successfully.",
              },
            });
            _this.$bvModal.hide("update-a-bonus");
            _this.getBonusList();
          } else {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Failed",
                icon: "CloseIcon",
                variant: "danger",
                text: resF.errorMessage || "Something went wrong.",
              },
            });
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    renderUpdateModal(bonus) {
      this.tempBonus = JSON.parse(JSON.stringify(bonus));
      this.$bvModal.show("update-a-bonus");
    },
    renderDeleteModal(bonus) {
      this.tempBonus = JSON.parse(JSON.stringify(bonus));
      this.$bvModal.show("delete-a-bonus-confimration-modal");
    },
    renderCreateBonusModal() {
      this.$bvModal.show("create-new-bonus");
    },
  },
  components: {
    PageWrapper,
    BButton,
    CreateNewBonusModal,
    SearchInput,
    CustomAppendText,
    CustomTable,
    DeleteBonusConfirmationModal,
    UpdateBonusModal,
  },
};
import PageWrapper from "@/views/components/Utilities/PageWrapper.vue";
import SearchInput from "@/views/components/Utilities/SearchInput.vue";
import CustomTable from "@/views/components/Utilities/CustomTable.vue";
import CreateNewBonusModal from "@/views/components/Bonus/CreateNewBonusModal.vue";
import CustomAppendText from "@/views/components/Utilities/CustomAppendText.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import DeleteBonusConfirmationModal from "@/views/components/Bonus/DeleteBonusConfirmationModal.vue";
import UpdateBonusModal from "@/views/components/Bonus/UpdateBonusModal.vue";
import {
  CreateANewBonusDeposit,
  GetBonusDepositList,
  DeleteABonusDeposit,
  UpdateABonusDeposit,
} from "@/libs/Api/BonusDeposit";

import { BButton } from "bootstrap-vue";
</script>
