import WebServiceRequest from "../Api/WebServiceRequest";

class CreateANewBonusDeposit extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("BonusDeposit/Create");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class GetBonusDepositList extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("BonusDeposit/GetList");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class DeleteABonusDeposit extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("BonusDeposit/Delete");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

class UpdateABonusDeposit extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("BonusDeposit/Update");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

export {
  CreateANewBonusDeposit,
  GetBonusDepositList,
  DeleteABonusDeposit,
  UpdateABonusDeposit,
};
